/* eslint-disable no-underscore-dangle */
export const getLoginBaseUrl = () =>
  ((global as any)._saeConversational &&
    (global as any)._saeConversational.baseLoginURL) ||
  process.env.REACT_APP_LOGIN_BASE

export const getApiBaseUrl = () =>
  ((global as any)._saeConversational &&
    (global as any)._saeConversational.apiURL) ||
  process.env.REACT_APP_API_URL
/* eslint-enable no-underscore-dangle */

export const openURL = (url: string): void => {
  if (window?.location) {
    try {
      const targetURL = new URL(url)
      const target =
        targetURL.hostname === window.location.hostname ? '_self' : '_blank'
      window.open(targetURL.href, target)
    } catch (_) {} // eslint-disable-line no-empty
  }
}
