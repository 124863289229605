import isElementVisible from 'utils/isElementVisible'

// eslint-disable-next-line no-shadow
export enum IsVisibleWatcherNodeState {
  // eslint-disable-next-line no-unused-vars
  Visible = 'visible',
  // eslint-disable-next-line no-unused-vars
  Hidden = 'hidden',
  // eslint-disable-next-line no-unused-vars
  Unknown = ''
}

type WatcherResult = {
  index: number
  state: IsVisibleWatcherNodeState
  changed: boolean
}

/**
 * @param indexes: represent index of node that is visible in input array
 */
// eslint-disable-next-line no-unused-vars
type CssPropertyWatcherCallback = (indexes: WatcherResult[]) => void

interface IWatchableItem {
  targetNode: HTMLElement | null
  checkChildren?: boolean
}

interface InitialProps {
  targetNodes: IWatchableItem[]
  checkResultCallback: CssPropertyWatcherCallback
}

export default class IsVisibleWatcher {
  targetNodes: IWatchableItem[]
  checkResultCallback: CssPropertyWatcherCallback
  interval?: NodeJS.Timeout
  checkChildren?: boolean
  previousStates: WatcherResult[] = []

  constructor(initial: InitialProps) {
    const { targetNodes, checkResultCallback } = initial
    this.targetNodes = targetNodes
    this.checkResultCallback = checkResultCallback
    this.init()

    this.previousStates = targetNodes.map((_el, index) => ({
      index,
      changed: true,
      state: IsVisibleWatcherNodeState.Unknown
    }))
  }

  processNode = (item: IWatchableItem, index: number): WatcherResult => {
    const { targetNode, checkChildren } = item

    const newIsVisible = isElementVisible(targetNode, checkChildren)

    const newState: IsVisibleWatcherNodeState = newIsVisible
      ? IsVisibleWatcherNodeState.Visible
      : IsVisibleWatcherNodeState.Hidden

    const previousState = this.previousStates[index].state

    this.previousStates[index].state = newState

    return {
      index,
      changed: newState !== previousState,
      state: newIsVisible
        ? IsVisibleWatcherNodeState.Visible
        : IsVisibleWatcherNodeState.Hidden
    }
  }

  init() {
    this.interval = setInterval(() => {
      const { targetNodes, checkResultCallback, processNode } = this

      if (targetNodes.filter(element => element.targetNode).length === 0) {
        checkResultCallback(
          Array.from(targetNodes.keys()).map(index => ({
            index,
            changed: false,
            state: IsVisibleWatcherNodeState.Hidden
          }))
        )
        this.disconnect()
        return
      }

      const indexesOfTriggered: WatcherResult[] = targetNodes.map(processNode)

      if (indexesOfTriggered.every(({ changed }) => !changed)) {
        return
      }

      checkResultCallback(indexesOfTriggered)
    }, 100)
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
