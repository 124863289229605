import Analytics from 'analytics'
import Axios from 'axios'

import { getApiBaseUrl } from 'utils/url'

const normalizeUrl = (url: string) => encodeURI(decodeURIComponent(url))

const normalizedProperties = (data: any) => {
  if (data?.properties?.url) {
    // eslint-disable-next-line no-param-reassign
    data.properties.url = normalizeUrl(data.properties.url)
  } else {
    // eslint-disable-next-line no-param-reassign
    data.properties.url = normalizeUrl(window.location.href)
  }

  if (data?.properties?.referrer) {
    // eslint-disable-next-line no-param-reassign
    data.properties.referrer = normalizeUrl(data.properties.referrer)
  }
}

const sanitizePayloadForNow = (payload: any) => {
  if (/^\//.test(payload.properties.url)) {
    /* eslint-disable no-param-reassign */
    payload.properties.path = payload.properties.url
    payload.properties.url = window.location.href
    /* eslint-enable no-param-reassign */
  }
}

const sendAnalyticRequest = async (data: any) => {
  try {
    await Axios({
      method: 'POST',
      baseURL: getApiBaseUrl(),
      url: '/analytics',
      data,
      withCredentials: true
    })
    /**
     * we can ignore it here
     * the analytics endpoints is forwarded to cloud function
     * errors can be found within gcp logs
     */
  } catch (_) {} // eslint-disable-line no-empty
}

const ourPlugin = () => ({
  name: 'sae-analytics',
  page: async ({ payload }: { payload: any }) => {
    sanitizePayloadForNow(payload)
    normalizedProperties(payload)

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('STAGING: Setting page for analytics')
      return
    }

    await sendAnalyticRequest(payload)
  },
  track: async ({ payload, instance }: { payload: any; instance: any }) => {
    const {
      page: {
        last: { properties }
      }
    } = instance.getState()
    sanitizePayloadForNow(payload)
    const data = {
      ...payload,
      properties: { ...properties, ...payload.properties }
    }
    normalizedProperties(data)

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('STAGING: track action was fired:', data)
      return
    }

    await sendAnalyticRequest(data)
  }
})

const config = {
  name: 'app-analytics',
  plugins: [ourPlugin()]
}

export default Analytics(config) as any
