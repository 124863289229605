// TIMERS

const oldDataTimeoutInHours: number = 24 // (in hours)

const storageIntervalInMs: number = 1000 // (in ms)

const millisecondToRetryRequestSending = 3000

const millisecondsBetweenMessagesAppear = 600

const inputAnimationDelay = 600

const mobileBreakpoint = 640

const shortViewportBreakpoint = 340
const checkVerificationDelay = 5000

const sentryWhitelistUrls = [/https:\/\/.*sae-conversational\.com/]

const buttonDataId = 'sae-conversational-button'
const rootId = 'sae-conversational-widget'

const avatarSize = 32

enum UrlHashesFragments {
  SupportChat = 'support-chat',
  SupportChatNotifications = 'support-chat-notification'
}

enum UrlGetParams {
  SupportChat = 'support-chat',
  SupportChatNotifications = 'support-chat-notification'
}

enum SentryExtraKeys {
  ErrorRequestBody = 'Error body',
  FailedAnalyticsRequest = 'Failed analytics request'
}

const myOrdersPath = '/account/orders.htm'

const shouldSentryInitialize =
  process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV === 'production'

const maxFileUploadSize = 5242880 // 5 MB

export {
  oldDataTimeoutInHours,
  storageIntervalInMs,
  millisecondToRetryRequestSending,
  millisecondsBetweenMessagesAppear,
  inputAnimationDelay,
  buttonDataId,
  rootId,
  mobileBreakpoint,
  UrlHashesFragments,
  shortViewportBreakpoint,
  checkVerificationDelay,
  SentryExtraKeys,
  sentryWhitelistUrls,
  UrlGetParams,
  myOrdersPath,
  shouldSentryInitialize,
  avatarSize,
  maxFileUploadSize
}
