function isElementVisible(
  element: HTMLElement | null,
  checkChildren: boolean = false
): boolean {
  if (!element) {
    return false
  }

  const styles = window.getComputedStyle(element)
  const elementIsVisible =
    !['none', ''].includes(styles.display) &&
    !['hidden', ''].includes(styles.visibility)

  if (!elementIsVisible) {
    return false
  }

  if (checkChildren) {
    const childrenArray = Array.from(element.children) as Array<HTMLElement>
    return childrenArray.some(child => isElementVisible(child))
  }

  return elementIsVisible
}

export default isElementVisible
