import Message from 'entities/Message'
import { oldDataTimeoutInHours } from 'config'
import ContextOfConversation from 'entities/ContextOfConversation'
import isSessionStorageExists from 'utils/isSessionStorageExists'

export enum SessionStorageKey {
  SessionStorageMessagesKey = 'sae-conversational-messages',
  SessionStorageContextsKey = 'sae-conversational-contexts',
  SessionStorageLastUserKey = 'sae-conversational-last-user',
  SessionStorageUserlike = 'sae-conversational-userlike'
}

class CustomStorage {
  private storage: Partial<Record<SessionStorageKey, string>> = {}

  public getItem = (key: SessionStorageKey): string | null => {
    return this.storage[key] || null
  }

  public setItem = (key: SessionStorageKey, value: string): void => {
    this.storage[key] = value
  }

  public removeItem = (key: SessionStorageKey): void => {
    delete this.storage[key]
  }

  public clear = (): void => {
    this.storage = {}
  }
}

export const storage = isSessionStorageExists()
  ? sessionStorage
  : new CustomStorage()

export const clearSessionStorage = () => {
  storage.removeItem(SessionStorageKey.SessionStorageMessagesKey)
  storage.removeItem(SessionStorageKey.SessionStorageContextsKey)
  storage.removeItem(SessionStorageKey.SessionStorageLastUserKey)
  storage.removeItem(SessionStorageKey.SessionStorageUserlike)
}

const loadItem = (key: SessionStorageKey, defaultValue: any): any => {
  try {
    const serialisedValue = storage.getItem(key)
    if (serialisedValue === null || typeof serialisedValue === 'undefined') {
      return defaultValue
    }
    return JSON.parse(serialisedValue)
  } catch (error) {
    if (error instanceof SyntaxError) {
      // eslint-disable-next-line no-console
      console.error(
        `Local storage contains invalid JSON under ${key}. Clearing whole sessionStorage`
      )
      clearSessionStorage()
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `Failed to load ${key} from local storage: ${error.message}`
      )
    }
    return defaultValue
  }
}

const saveItem = (key: SessionStorageKey, value: any): void => {
  try {
    const serialisedValue = JSON.stringify(value)
    storage.setItem(key, serialisedValue)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to save ${key} to local storage: ${error.message}`)
  }
}

export const loadMessagesFromSessionStorage = (): Message[] => {
  const messages: Message[] = loadItem(
    SessionStorageKey.SessionStorageMessagesKey,
    []
  )
  if (!Array.isArray(messages) || messages.length === 0) {
    return []
  }

  const nowDate = new Date().getTime()
  const lastMessageDate = new Date(
    messages[messages.length - 1].timestamp
  ).getTime()

  const hoursInMilliseconds = 1000 * 60 * 60 * oldDataTimeoutInHours

  if (nowDate - lastMessageDate > hoursInMilliseconds) {
    clearSessionStorage()
    return []
  }

  return messages.map((item: Message) => ({
    ...item,
    timestamp: new Date(item.timestamp)
  }))
}

export const saveMessagesToSessionStorage = (responses: Message[]): void => {
  const filtredResponses = responses.filter(el => !el.messageObject.protected)
  saveItem(
    SessionStorageKey.SessionStorageMessagesKey,
    filtredResponses.map(el => ({
      ...el,
      messageObject: { ...el.messageObject, delayBeforeAppear: undefined }
    }))
  )
}

export const loadContextsFromSessionStorage = (): ContextOfConversation[] => {
  return loadItem(SessionStorageKey.SessionStorageContextsKey, [])
}

export const saveContextsToSessionStorage = (
  contexts: ContextOfConversation[]
): void => {
  return saveItem(SessionStorageKey.SessionStorageContextsKey, contexts)
}

export const saveStateToSessionStorage = (
  messages: Message[],
  contexts: ContextOfConversation[]
) => {
  saveContextsToSessionStorage(contexts)
  saveMessagesToSessionStorage(messages)
}

export const loadStateFromSessionStorage = () => {
  return {
    contexts: loadContextsFromSessionStorage(),
    messages: loadMessagesFromSessionStorage()
  }
}

export const setUserlikeSession = (value: boolean) => {
  if (value) {
    saveItem(SessionStorageKey.SessionStorageUserlike, 'true')
  } else {
    storage.removeItem(SessionStorageKey.SessionStorageUserlike)
  }
}

export const getUserlikeSession = () =>
  loadItem(SessionStorageKey.SessionStorageUserlike, null)
