import customAnalytics from 'utils/analytics'
import shouldChatWidgetOpen from 'utils/shouldChatWidgetOpen'
import TRACKING_INFORMATION from 'utils/trackingInformation'

// Needs to happen before any event tracking
const trackPageLoaded = () => {
  customAnalytics.page(() => {
    customAnalytics.track(TRACKING_INFORMATION.widgetLoaded.action, {
      category: TRACKING_INFORMATION.widgetLoaded.category
    })

    if (shouldChatWidgetOpen()) {
      customAnalytics.track(TRACKING_INFORMATION.widgetOpenedByHash.action, {
        category: TRACKING_INFORMATION.widgetOpenedByHash.category,
        label: TRACKING_INFORMATION.widgetOpenedByHash.label
      })
    }
  })
}

export default trackPageLoaded
