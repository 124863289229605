const TRACKING_INFORMATION = {
  widgetLoaded: {
    action: 'widget-loaded',
    category: 'widget'
  },
  widgetOpenedByClick: {
    action: 'widget-opened',
    category: 'widget',
    label: 'by-click'
  },
  widgetOpenedByHash: {
    action: 'widget-opened',
    category: 'widget',
    label: 'by-urlfragment'
  },
  closeWidget: {
    action: 'widget-closed',
    category: 'widget',
    label: 'by-click'
  },
  inlineLinkClick: {
    action: 'inline-link-clicked',
    category: 'navigation'
  },
  orderLinkClick: {
    action: 'orderpicker-link-clicked',
    category: 'navigation'
  },
  buttonLinkClicked: {
    action: 'button-link-clicked',
    category: 'navigation'
  },
  loginSuccess: {
    action: 'login-success',
    label: 'success',
    category: 'addin-login'
  },
  loginFailure: {
    action: 'login-failure',
    label: 'failure',
    category: 'addin-login'
  },
  loginError: {
    action: 'login-error',
    label: 'error',
    category: 'addin-login'
  },
  messageSent: {
    action: 'message-sent',
    category: 'message'
  },
  addInLoginTriggered: {
    action: 'login-displayed',
    category: 'addin-login'
  },
  addInOrderpickerTriggered: {
    action: 'order-picker-displayed',
    category: 'addin-orderpicker'
  },
  addInOrderpickerClosed: {
    action: 'order-picker-closed',
    category: 'addin-orderpicker'
  },
  addInProductInfoTriggered: {
    action: 'productinfo-picker-displayed',
    category: 'addin-productinfo'
  },
  addInProductInfoClosed: {
    action: 'productinfo-picker-closed',
    category: 'addin-productinfo'
  },
  addInLoginClosed: {
    action: 'login-closed',
    category: 'addin-login'
  }
}

export default TRACKING_INFORMATION
