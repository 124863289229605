export default () => {
  const input = document.createElement('input')

  input.disabled = true

  input.style.position = 'fixed'
  input.style.top = '0'
  input.style.height = '0'
  input.style.width = '0'
  input.style.padding = '0'
  input.style.overflow = 'hidden'
  input.style.outline = 'none'
  input.style.border = 'none'

  return input
}
