import { setupRootStyle, startRootStyleWatchers } from 'utils/rootStyle'
import createOpenChatButton from 'utils/createOpenButton'
import createInvisibleInput from 'utils/createInvisibleInput'
import customAnalytics from 'utils/analytics'
import checkUserlikeState from 'utils/userlike'
import {
  mobileBreakpoint,
  rootId,
  UrlHashesFragments,
  UrlGetParams
} from 'config'
import styles from 'components/atoms/ChatButton/index.module.css'
import TRACKING_INFORMATION from 'utils/trackingInformation'
import shouldChatWidgetOpen from 'utils/shouldChatWidgetOpen'
import trackPageLoaded from 'utils/trackPageLoaded'

const urlHash = window.location.hash
const getParams = Array.from(new URLSearchParams(window.location.search).keys())

const withCounter =
  window.innerWidth <= mobileBreakpoint &&
  (urlHash.indexOf(UrlHashesFragments.SupportChatNotifications) !== -1 ||
    getParams.includes(UrlGetParams.SupportChatNotifications))

const root = document.getElementById(rootId)!

const button = createOpenChatButton(withCounter)

// iOS will not deploy virtual keyboard if we focus an input outside of
// user ineracion event. But it does not hide the keyboard when keyboard
// is already out and we jump between inputs.
// We create this (invisible) input so that keyboard gets shown and
// later when message input is rendered, we switch to that.
const input = createInvisibleInput()

const loadAndOpenApplication = async () => {
  const { isOpened } = styles
  const imgs = button.querySelectorAll('img')

  imgs.forEach(el => {
    el.classList.add(isOpened)
  })

  input.disabled = true
  /**
   * Uncomment next line and change `disabled` to `false` if you need initial focus on input fields
   * for phones
   */
  // input.focus()

  /* webpackMode: "lazy" */
  // eslint-disable-next-line no-unused-expressions
  ;(await import('./init')).default(root, input)

  setTimeout(() => {
    input.disabled = true
  }, 50)
}

button.addEventListener('click', () => {
  customAnalytics.track(TRACKING_INFORMATION.widgetOpenedByClick.action, {
    category: TRACKING_INFORMATION.widgetOpenedByClick.category,
    label: TRACKING_INFORMATION.widgetOpenedByClick.label
  })
  loadAndOpenApplication()
})

if (shouldChatWidgetOpen() && !withCounter) {
  loadAndOpenApplication()
}

trackPageLoaded()
setupRootStyle(root)
startRootStyleWatchers(root)
checkUserlikeState()

root.appendChild(button)
