import {
  MultipleActionsMessage,
  MultipleActionsMessageActionPayload
} from './Messages/MultipleActionsMessage'
import { OrderListMessage } from './Messages/OrderListMessage'
import { ProductListMessage } from './Messages/ProductListMessage'
import { TextMessage, TextMessagePayload } from './Messages/TextMessage'
import { ClosePromptMessage } from './Messages/ClosePromptMessage'

/**
 *  Represents, who sent this message.
 *  `assistant` - backend generated message
 *  `user` - obviously
 *  `userlike` - sent by Userlike
 */
export enum Sender {
  Assistant = 'assistant',
  User = 'user',
  Userlike = 'userlike'
}

export enum AnswerTypes {
  Text = 'text',
  MultipleActions = 'multiple-actions',
  SelectList = 'select-list',
  OrderList = 'order-list',
  UtilityMessage = 'utility-message',
  Input = 'input',
  Login = 'login',
  Inner = 'inner',
  File = 'file',
  Image = 'image',
  ClosePrompt = 'close-prompt'
}

export enum CustomHandlers {
  CloseChat = 'close_chat'
}

export interface BaseParameters {
  loginRequired?: boolean
  loginSuccess?: string
  loginFailure?: string
  loginCancel?: string
  initUserlike?: boolean
  initPusher?: boolean
  customHandler?: CustomHandlers
  href?: string
}

export interface BasePayload<
  ParametersType extends BaseParameters = BaseParameters
> {
  parameters?: ParametersType
  inputPlaceholder?: string
}
export interface BaseMessage<PayloadType extends BasePayload = BasePayload> {
  type: AnswerTypes
  delayBeforeAppear?: number
  payload?: PayloadType
  /**
   * If `protected` = true message doesnt go to Session Storage
   */
  protected?: boolean
  inputPlaceholder?: string
}

export interface EventParametersPayload extends BaseParameters {
  content: string
}

/**
 * Type for actions of select list
 */
export interface SelectListMessageActionPayload extends BasePayload {
  id: number
}

export interface UserMessage
  extends BaseMessage<
    | MultipleActionsMessageActionPayload
    | SelectListMessageActionPayload
    | TextMessagePayload
  > {
  content?: string
}

export type AnyTypeMessage =
  | TextMessage
  | MultipleActionsMessage
  | ProductListMessage
  | OrderListMessage
  | ClosePromptMessage

/**
 * Message is Entity that we use for render user's and assistant's messages.
 * Can contain content of generic types: `TextMessage` or `MultipleActionsMessage` etc...
 * this content reflects data and metadata of answer
 */
export default interface Message<TypeOfContent = AnyTypeMessage> {
  sender: Sender
  timestamp: Date
  messageObject: TypeOfContent
  avatar?: string
  name?: string
}

export interface MessageGroup {
  key: string
  sender: Sender
  messages: Message[]
  isLast?: boolean
}
