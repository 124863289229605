import getLanguage from 'locales/getLanguage'
import styles from 'components/atoms/ChatButton/index.module.css'
import classNames from 'classnames'
import { buttonDataId } from 'config'
import { Button, ButtonClose } from 'components/assets'

const labelsByLanguage = {
  de: 'Open Pharmacy Assistant', // TODO Update translation once we get it
  en: 'Open Pharmacy Assistant',
  default: 'Open Pharmacy Assistant'
} as any

const {
  chatButton,
  chatButtonBackside,
  chatButtonFrontside,
  button: buttonStyle,
  buttonLabel
} = styles

export default (withCounter?: boolean) => {
  const lang = getLanguage()

  const chatLabel = labelsByLanguage[lang]
    ? labelsByLanguage[lang]
    : labelsByLanguage.default

  const button = document.createElement('button')

  button.type = 'button'
  button.dataset.cy = 'Modal button'
  button.dataset.id = buttonDataId

  button.className = classNames(buttonStyle)

  if (withCounter) {
    button.classList.add(styles.withCounter)
  }

  const label = document.createElement('span')
  label.className = buttonLabel
  label.textContent = chatLabel

  const image = document.createElement('img')
  image.className = classNames(chatButton, chatButtonFrontside)
  image.alt = ''
  image.src = Button

  const closeImage = image.cloneNode(true) as HTMLImageElement
  closeImage.src = ButtonClose
  closeImage.className = classNames(chatButton, chatButtonBackside)

  const counter = document.createElement('div')
  counter.className = styles.counter
  counter.innerText = '1'
  button.appendChild(counter)

  button.appendChild(label)
  button.appendChild(closeImage)
  button.appendChild(image)

  button.classList.add(styles.initialWiggle)

  setTimeout(() => {
    button.classList.remove(styles.initialWiggle)
  }, 1200)
  return button
}
