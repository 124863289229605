import isElementVisible from './isElementVisible'
import IsVisibleWatcher, { IsVisibleWatcherNodeState } from './IsVisibleWatcher'

const LEGACY_LINK_TO_TOP_SELECTOR = '.link-scroll-top'
const NEWSLETTER_BANNER_SELECTOR = '#ab-sticky-nl'
const STICKY_BUY_BOX_SELECTOR = '[data-clientside-hook="stickyBuyBox"]'

const legacyLinkToTop: HTMLDivElement | null = document.querySelector(
  LEGACY_LINK_TO_TOP_SELECTOR
)
const newsleterBanner: HTMLElement | null = document.querySelector(
  NEWSLETTER_BANNER_SELECTOR
)
const stickyBuyBox: HTMLElement | null = document.querySelector(
  STICKY_BUY_BOX_SELECTOR
)

const pushers = [legacyLinkToTop, newsleterBanner].filter(Boolean) as Readonly<
  Array<HTMLElement>
>

const hiders = [
  {
    selector: stickyBuyBox,
    checkChildren: true
  }
].filter(hider => Boolean(hider.selector))

function determineVisibility(): boolean {
  return !hiders
    .filter(Boolean)
    .some(({ selector, checkChildren }) =>
      isElementVisible(selector, checkChildren)
    )
}

function determineMarginBottom(): string {
  // eslint-disable-next-line no-restricted-syntax
  for (const pusher of pushers) {
    const { clientHeight } = pusher
    if (clientHeight) {
      return `${clientHeight + 10}px`
    }
  }

  return '0'
}

export const setupRootStyle = (root: HTMLElement) => {
  // eslint-disable-next-line no-param-reassign
  root.hidden = !determineVisibility()

  Object.assign(root.style, {
    position: 'fixed',
    zIndex: '99999991',
    left: '10px',
    top: '10px',
    width: CSS.supports('width: calc(1em)') ? 'calc(100% - 20px)' : '100%',
    height: '0',
    textAlign: 'right'
  })

  if (CSS.supports('pointer-events: none')) {
    Object.assign(root.style, {
      pointerEvents: 'none',
      top: '10px',
      left: '10px',
      bottom: '10px',
      right: '10px',
      height: 'auto',
      marginBottom: determineMarginBottom(),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end'
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-unused-expressions
    root.clientHeight

    Object.assign(root.style, {
      transition: 'margin-bottom 0.3s cubic-bezier(0.3, -0.4, 0, 1.5)'
    })
  }
}

export const startRootStyleWatchers = (root: HTMLElement) => {
  if (CSS.supports('pointer-events: none')) {
    // eslint-disable-next-line no-new
    new IsVisibleWatcher({
      targetNodes: pushers.map(pusher => ({ targetNode: pusher })),
      checkResultCallback: () => {
        const marginBottom = determineMarginBottom()
        Object.assign(root.style, {
          marginBottom
        })
      }
    })
  }

  // eslint-disable-next-line no-new
  new IsVisibleWatcher({
    targetNodes: hiders.map(hider => ({
      targetNode: hider.selector,
      checkChildren: hider.checkChildren
    })),
    checkResultCallback: stateArray => {
      const isVisible = !stateArray.some(
        state => state.state === IsVisibleWatcherNodeState.Visible
      )
      // eslint-disable-next-line no-param-reassign
      root.hidden = !isVisible
    }
  })
}
