import { messages as messagesEn } from './en/messages'
import { messages as messagesDe } from './de/messages'

const CATALOGS = {
  de: messagesDe,
  en: messagesEn
}

export default () => {
  const documentLanguage = document?.documentElement?.lang?.split(/[-_]/)[0]
  return documentLanguage in CATALOGS
    ? documentLanguage
    : Object.keys(CATALOGS)[1]
}
