import Axios from 'axios'
import ContextOfConversation from 'entities/ContextOfConversation'
import { AnswerTypes, UserMessage } from 'entities/Message'
import TVerifyUserResponse from 'entities/TVerifyUserResponse'
import customAnalytics from 'utils/analytics'
import TRACKING_INFORMATION from 'utils/trackingInformation'
import { getLoginBaseUrl, getApiBaseUrl } from 'utils/url'
import sanitizeUserMessage from 'utils/sanitizeUserMessage'

const axiosInstance = Axios.create()

interface Request {
  endpoint: string
  payload?: object
  refreshUuid?: boolean
}

interface Headers {
  'Content-Type': string
  'X-Refresh-UUID'?: boolean
}

interface UserlikePayload {
  chatHistory?: string
  attachmentId?: string
}

export const sendRequest = async (props: Request) => {
  const { endpoint, payload = {}, refreshUuid = false } = props
  const verifyToken = process.env.REACT_APP_API_TOKEN
  const headers: Headers = {
    'Content-Type': 'application/json; charset=utf-8'
  }

  if (refreshUuid) {
    headers['X-Refresh-UUID'] = true
  }

  return axiosInstance({
    baseURL: getApiBaseUrl(),
    url: `/${endpoint}`,
    method: 'POST',
    headers,
    data: {
      ...payload,
      verifyToken
    },
    withCredentials: true
  })
}

export const sendMessage = async (
  message: UserMessage,
  contexts: ContextOfConversation[]
) => {
  const payload = {
    ...message,
    contexts
  }
  const { data } = await sendRequest({
    endpoint: 'webchat',
    payload
  })

  if (
    message.type === AnswerTypes.Text ||
    message.type === AnswerTypes.MultipleActions
  ) {
    customAnalytics.track(TRACKING_INFORMATION.messageSent.action, {
      category: TRACKING_INFORMATION.messageSent.category,
      label: message.type
    })
  }

  return data
}

export const sendUserlike = async (
  value: string,
  { chatHistory, attachmentId }: UserlikePayload = {}
) => {
  const payload = {
    type: 'userlike',
    content: sanitizeUserMessage(value),
    chatHistory,
    attachmentId
  }
  const { data } = await sendRequest({
    endpoint: 'webchat',
    payload
  })

  // TODO add tracking events?

  return data
}

export const updateUserLikeData = async (
  refreshUuid: boolean,
  message?: string
) => {
  const { data } = await sendRequest({
    endpoint: 'userlike-userdata',
    payload: {
      noAction: refreshUuid,
      message
    },
    refreshUuid
  })

  return data
}

export const authorizeQuery = async (username: string, password: string) => {
  const bodyFormData = new FormData()
  bodyFormData.append('§loginForm.email', username)
  bodyFormData.append('§loginForm.password', password)

  await axiosInstance({
    baseURL: getLoginBaseUrl(),
    url: '/nl/login.htm',
    method: 'post',
    withCredentials: true,
    data: bodyFormData
  })
}

export const verifyUserQuery = async (): Promise<TVerifyUserResponse> => {
  const { data } = await axiosInstance({
    baseURL: getLoginBaseUrl(),
    url: '/shopwebclientsession.go',
    withCredentials: true
  })

  return data
}

export const uploadFileRequest = async (file: File) => {
  const verifyToken = process.env.REACT_APP_API_TOKEN

  const formData = new FormData()
  formData.append('file', file, file.name)

  const { data } = await axiosInstance({
    baseURL: getApiBaseUrl(),
    url: '/upload',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Verify-Token': verifyToken
    },
    data: formData,
    withCredentials: true
  })

  return data
}

export const deleteFileRequest = async (fileName: string) => {
  const verifyToken = process.env.REACT_APP_API_TOKEN

  const { data } = await axiosInstance({
    baseURL: getApiBaseUrl(),
    url: `/upload/${fileName}`,
    method: 'DELETE',
    headers: {
      'X-Verify-Token': verifyToken
    },
    withCredentials: true
  })

  return data
}
