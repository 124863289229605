const isSessionStorageExists = () => {
  try {
    const mod = '__storage_test__'
    sessionStorage.setItem(mod, mod)
    sessionStorage.removeItem(mod)
    return true
  } catch (e) {
    return false
  }
}

export default isSessionStorageExists
