import sanitizeHtml from 'sanitize-html'
import { UrlGetParams } from 'config'

interface Urls {
  [key: string]: string
}

export const shopApothekeRegex = /https?:\/\/.*(shop-apotheke|redteclab)\.[^\s]+/g

const replaceUrls = (message: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  const matchedUrls = message.match(urlRegex)

  if (!matchedUrls) {
    return message
  }

  const urls: Urls = matchedUrls.reduce((acc: Urls, val: string) => {
    let url

    if (shopApothekeRegex.test(val)) {
      url = `<a href="${val}?${UrlGetParams.SupportChat}=true">${val}</a>`
    } else {
      url = `<a href="${val}" target="_blank" rel="noopener noreferrer">${val}</a>`
    }

    acc[val] = url

    return acc
  }, {})

  return message.replace(urlRegex, url => urls[url])
}

const sanitizeUserMessage = (message: string, allowLinks: boolean = false) => {
  const sanitized = sanitizeHtml(message, {
    allowedTags: []
  })

  return allowLinks ? replaceUrls(sanitized) : sanitized
}

export default sanitizeUserMessage
