import { verifyUserQuery, updateUserLikeData } from 'api'
import { SessionStorageKey, storage } from 'utils/sessionStorage'

const checkUserlikeState = async () => {
  const lastUserId = storage.getItem(
    SessionStorageKey.SessionStorageLastUserKey
  )
  const userLikeIsEnabled = storage.getItem(
    SessionStorageKey.SessionStorageUserlike
  )

  if (userLikeIsEnabled) {
    let isLoggedIn = false

    try {
      const user = await verifyUserQuery()

      isLoggedIn = user?.isLoggedIn
    } catch (_) {} // eslint-disable-line no-empty

    if (isLoggedIn && !lastUserId) {
      await updateUserLikeData(false)
    } else if (!isLoggedIn && lastUserId) {
      storage.removeItem(SessionStorageKey.SessionStorageUserlike)

      await updateUserLikeData(
        true,
        'Diese Konversation wurde aufgrund der Abmeldung des Benutzers beendet.'
      )
    }
  }
}

export default checkUserlikeState
