import { UrlHashesFragments, UrlGetParams } from 'config'

const shouldChatWidgetOpen = () => {
  const urlHash = window.location.hash
  const getParams = Array.from(
    new URLSearchParams(window.location.search).keys()
  )

  return (
    urlHash.indexOf(UrlHashesFragments.SupportChat) !== -1 ||
    urlHash.indexOf(UrlHashesFragments.SupportChatNotifications) !== -1 ||
    getParams.includes(UrlGetParams.SupportChat) ||
    getParams.includes(UrlGetParams.SupportChatNotifications)
  )
}

export default shouldChatWidgetOpen
